import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

export default function ThirdPartyScript({ queryKey }) {

  // This component doesn't render anything, just fetches a <script>
  // snippet from the server and appends it to the end of the document
  // body.
  const query = useQuery({ queryKey });

  useEffect(() => {
    if (query?.data) {
      // this bizarre method is the only way to insert a <script>
      // from a string, and have it execute.
      // (see https://grrr.tech/posts/create-dom-node-from-html-string/)
      const code = document.createRange().createContextualFragment(query.data);
      document.body.appendChild(code);
    }
  }, [ query?.data ]);

  return null;
}