import React from "react";
import { Route, Redirect } from "react-router-dom";

export const OwnerRoute = ({
  component: Component,
  user,
  condition,
  location,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated || user.role !== "owner") {
          return (
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          );
        }

        if (condition) {
          // if user is not allowed to view page - ie owner without a coach cant chat
          const passedCondition = !!condition.rule;
          if (!passedCondition) {
            return (
              <Redirect to={{ pathname: "/", state: { from: location } }} />
            );
          }
        }

        return <Component user={user} {...rest} {...props} />;
      }}
    />
  );
};

