import { timeoutPromise } from "./exceptions";
import { loadChatToken } from '../api/me';

let chatClient = null;

const refreshToken = async () => {
  console.log('Refreshing chat token');
  const { token } = await loadChatToken();
  chatClient.updateToken(token);
};

export const initChatClient = async (token) => {
  try {
    await timeoutPromise(createClient(token), 10000);
  } catch (e) {
    console.log('Timeout connecting to chat');
    throw e;
  }
}

const createClient = async (token) => {
  return new Promise((resolve) => {

    // eslint-disable-next-line
    chatClient = new Twilio.Conversations.Client(token);

    // this might happen later in the session
    chatClient.on('tokenAboutToExpire', (_message) => {
      refreshToken();
    });
    chatClient.on('stateChanged', (state) => {
      if (state === 'initialized') {
        resolve();
      }
    });

  });
};

export const getChatData = async (channel_sid) => {

  // if the initial connection (initChatClient) failed,
  // show an error message on the chat screen which prompts the user to reload
  if (!chatClient) {
    throw new Error('Attempt to use chat client before initialization');
  }

  try {
    const { identity } = chatClient.user;
    const channel = await timeoutPromise(chatClient.getConversationBySid(channel_sid), 10000);

    return { identity, channel };
  } catch (e) {
    console.log('Timeout connecting to chat');
    throw e;
  }
};
