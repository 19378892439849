import React from 'react';
import { post } from '../../api/requests';

import ErrorPage from './ErrorPage';
import { customAlphabet } from 'nanoid';
const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 7);

import StackTrace from 'stacktrace-js';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { 
      hasError: true,
      errorId: nanoid(),
      status: error?.status,
      name: error?.name,
    };
  }

  async componentDidCatch(error, _errorInfo) {
    try {
      const { errorId, status, name } = this.state;
      let frames = [];

      if (error instanceof Error) {
        frames = await StackTrace.fromError(error)
      }

      const stack = frames.map((frame) => frame.toString()).join('\n');

      await post('/v1/error', {
        errorId,
        message: error?.message || 'unknown',
        stack,
        status,
        name,
      });

    } catch (_e) {
      // not much we can do here
    }
  }

  render() {

    const { hasError, errorId, status, name } = this.state;

    if (hasError) {

      let buttonText = 'Take me home';

      let message = (
        <>
          <p>An error has occurred. Please take a screenshot or note the following error code:</p>
          <p className="lead">{errorId}</p>
        </>
      );

      if (status === 401) {
        message = <p>Your session has expired. Please log in again.</p>;
      }

      if (name === 'ChunkLoadError') {
        message = <p>We have updated the app. Please reload to continue with the latest version.</p>
        buttonText = 'Reload';
      }

      return (
        <ErrorPage buttonText={buttonText}>
          { message }
        </ErrorPage>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;