import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { initChatWithCoach, initChatWithFamily } from '../api/chat';
import { 
  getChatData,
} from '../helpers/chat';
import { 
  chatChannelKey, 
  chatKey, 
  chatMessagesKey, 
  chatUnreadKey 
} from '../query/queryKeys';
import debounce from 'lodash/debounce';
import { setHasUnreadChatMessages } from '../redux/actions';

const PAGE_SIZE = 30;

// the 'defer' parameter, if set to true, will disable all loading operations
// (can be used to delay loading until a chat is visible on the screen)

// If coachId is passed, this is a chat for a client talking to their coach.
// If clientId is passed, this is a chat for a coach talking to one of their clients.
// They shouldn't both be passed.
const useChat = ({ clientId, coachId }, defer) => {

  const [ messages, setMessages ] = useState([]);
  const [ numPages, setNumPages ] = useState(1);
  const [ isSomeoneTyping, setIsSomeoneTyping ] = useState(false);

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  // this is true if I am the coach
  const isCoach = Boolean(clientId);

  // get the Twilio SID of the chat channel
  const sidQuery = useQuery({
    queryKey: chatKey(isCoach ? clientId : coachId, isCoach),
    queryFn: () => isCoach ? initChatWithFamily(clientId) : initChatWithCoach(coachId),

    // we don't expect this to ever change
    // we can cache it pretty aggressively
    staleTime: Infinity,
    enabled: !defer && (Boolean(clientId) || Boolean(coachId))
  });

  const { channel_sid: channelSid } = sidQuery?.data || {};

  const channelQuery = useQuery({
    queryKey: chatChannelKey(channelSid),
    queryFn: () => getChatData(channelSid),
    staleTime: Infinity,
    enabled: Boolean(channelSid)
  });

  const { identity, channel } = channelQuery?.data || {};

  useEffect(() => {
    if (!channel) return;

    channel.on("messageAdded", message => {
      setMessages((messages) => {
        return [ message, ...messages ];
      });
    });

    channel.on("typingStarted", () => {
      setIsSomeoneTyping(true);
    });

    channel.on("typingEnded", () => {
      setIsSomeoneTyping(false);
    });

  }, [ channel ]);

  const messagesQuery = useQuery({
    queryKey: chatMessagesKey(channel?.sid, numPages),
    queryFn: async () => {
      if (!channel) return { messages: [], hasPrevPage: false };

      const paginator = await channel.getMessages(numPages * PAGE_SIZE);
      const messages = [...paginator.items].reverse();
      const hasPrevPage = paginator.hasPrevPage;

      return { messages, hasPrevPage };
    },
    enabled: false,
    staleTime: 2000,
  });

  const { hasPrevPage } = messagesQuery?.data || {};

  useEffect(() => {
    if (messagesQuery?.data?.messages?.length > 0) {
      setMessages(messagesQuery?.data?.messages);
    }
  }, [ messagesQuery?.data?.messages ]);

  async function loadMessages() {
    if (!channel) return;
    messagesQuery.refetch();
  }

  const unreadQuery = useQuery({
    queryKey: chatUnreadKey(channel?.sid),
    queryFn: () => channel.getUnreadMessagesCount(),
    enabled: Boolean(channel),
    refetchInterval: 5000,

    // if any chat has unread messages, we'll set a global flag so that it
    // can be displayed in the navbar
    onSuccess: (data) => dispatch(setHasUnreadChatMessages(data > 0))
  });
  const unreadCount = unreadQuery?.data;

  async function sendMessage(message) {
    const index = await channel?.sendMessage(message);

    // when you send a message, you've already read your own message!
    const unread = await channel?.updateLastReadMessageIndex(index);
    queryClient.setQueryData(chatUnreadKey(channel?.sid), unread);
    dispatch(setHasUnreadChatMessages(unread > 0));
  }

  const iAmTyping = debounce(() => {
    channel?.typing();
  }, 1000);

  function setAllMessagesRead() {
    channel?.setAllMessagesRead();

    // the unread count is not "real-time" so we'll update our local 
    // cache directly while the Twilio server gets up-to-date
    queryClient.setQueryData(chatUnreadKey(channel?.sid), 0);
    dispatch(setHasUnreadChatMessages(false));
  }
  
  // this function can be used as a click handler, we don't care about the click event
  function loadPrevPage(_ignoredEvent) {
    setNumPages(p => p + 1);
  }

  useEffect(() => {
    messagesQuery.refetch();
  }, [ numPages ]);

  const isError = 
    sidQuery.isError || channelQuery.isError || 
    messagesQuery.isError || unreadQuery.isError;

  /*
  useEffect(() => {
    if (isError) {
      Sentry.captureException({
        sidQuery: sidQuery.error,
        channelQuery: channelQuery.error,
        messagesQuery: messagesQuery.error,
        unreadQuery: unreadQuery.error,
      });
    }
  }, [ isError, sidQuery.error, channelQuery.error, messagesQuery.error, unreadQuery.error ]);
  */

  return {
    isChatInitializing: sidQuery.isLoading || channelQuery.isLoading,
    isError,
    identity,

    loadMessages,
    messages,
    unreadCount,

    hasPrevPage,
    loadPrevPage,

    sendMessage,
    isSomeoneTyping,
    iAmTyping,

    setAllMessagesRead,
  };

};

export default useChat;
