import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import useChat from "../../hooks/useChat";

import { House, Training, Settings, History, Chat, Plan, PawPrints } from "./icons";
import ROUTES from "../../constants/routes";
import isUndefined from 'lodash/isUndefined';

const className = "navigation__item";
const notificationClassName = "navigation__item--notification";
const activeClassName = "navigation__item--selected";

const ownerChat = {
  to: "/chat",
  label: "Chat",
  Icon: Chat
};

const items = {
  owner: [
    {
      to: "/dashboard",
      label: "Home",
      Icon: House
    },
    {
      to: "/training",
      label: "Training",
      Icon: Training
    },
    {
      to: "/history",
      label: "History",
      Icon: History
    },
    {
      to: "/activity",
      label: "Activity",
      Icon: PawPrints,
    },
    {
      to: "/settings",
      label: "Settings",
      Icon: Settings
    }
  ],
  coach: [
    {
      to: "/trainer/dashboard",
      label: "Home",
      Icon: House
    },
    {
      to: ROUTES.COACH.PLANS,
      label: "Plans",
      Icon: Plan
    },
    {
      to: "/trainer/chat",
      label: "Chat",
      Icon: Chat
    },
    {
      to: "/trainer/settings",
      label: "Settings",
      Icon: Settings
    }
  ]
};

const Navigation = ({ user }) => {
  const { role } = user;
  let nav = items[role];
  if (user?.family?.coach) {
    const navItems = [...nav];
    navItems.splice(3, 0, ownerChat);
    nav = navItems;
  }

  // to show the chat notification badge, it works a little differently
  // for clients and coaches:
  // - for clients, they are only part of one chat (with their coach), so we 
  // can query the Twilio API directly with the useChat hook
  // - for coaches, they are a part of many chats (one with each client), so 
  // we don't want to slam the Twilio API with many requests and slow down 
  // the loading time. Instead, the useChat hook will be used by other parts of the 
  // UI and load chats on demand. If any of those chats have unread messages, then 
  // it will update a global flag in the redux state.

  const coachId = user?.family?.coach?.id;
  const hasCoach = !isUndefined(coachId);
  const { unreadCount } = useChat({ coachId }, !hasCoach);

  const globalUnread = useSelector((state) => state.hasUnreadChatMessages);

  const hasUnreadChatMessages = unreadCount > 0 || globalUnread;

  return (
    <nav className="navigation" data-testid="app-nav">
      {nav.map(({ Icon, to, label }) => (
        <NavLink
          key={to}
          to={to}
          className={className}
          activeClassName={activeClassName}
          aria-label={label}
          data-testid={`navigation-item-${label}`}
        >
          <div className="d-flex flex-column align-items-center">
            <div className={classNames({
              [notificationClassName]: label === "Chat" && hasUnreadChatMessages
            })}>
              <Icon />
            </div>
            <span className="mt-1 navigation__item--label">{label}</span>
          </div>
        </NavLink>
      ))}
    </nav>
  );
};

Navigation.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string.isRequired
  }).isRequired,
};

Navigation.defaultProps = {
};

export default Navigation;
