import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

import ProContext from "../../contexts/pro";
import ROUTES from "../../constants/routes";
import { SetupRoute } from "./SetupRoute";
import { AdminRoute } from "./AdminRoute";
import { CoachRoute } from "./CoachRoute";
import { OwnerRoute } from "./OwnerRoute";

import Navigation from "./Navigation";

import lazyRetry from "../../helpers/lazyRetry";

const Dashboard = lazyRetry(() => import("../dashboard/Dashboard"));
const Training = lazyRetry(() => import("../training/Training"));
const History = lazyRetry(() => import("../history/HistoryRouter"));
const Settings = lazyRetry(() => import("../settings/Settings"));
const Chat = lazyRetry(() => import("../owner/Chat"));

const LoginPage = lazyRetry(() => import("../login/LoginPage"));
const ForgotPassword = lazyRetry(() => import("../login/ForgotPassword"));
const NewPassword = lazyRetry(() => import("../login/NewPassword"));
const Register = lazyRetry(() => import("../login/Register"));
const Onboarding = lazyRetry(() => import("../login/Onboarding"));
const Complete = lazyRetry(() => import("../login/Complete"));

const Admin = lazyRetry(() => import("../admin/Admin"));
const Frontpage = lazyRetry(() => import("../frontpage"));

const Purchase = lazyRetry(() => import("../purchase/Purchase"));

const ErrorPage = lazyRetry(() => import("../errorpages/ErrorPage"));

const GoalDetails = lazyRetry(() => import("../owner/goals/GoalDetails"));
const Achievements = lazyRetry(() => import("../owner/achievements/Achievements"));
const Heroboards = lazyRetry(() => import("../owner/heroboards/Heroboards"));
const Friends = lazyRetry(() => import("../owner/social/Friends"));
const SocialOptIn = lazyRetry(() => import("../owner/social/SocialOptIn"));
const ActivityFeed = lazyRetry(() => import("../owner/feed/ActivityFeed"));
const ActivityHome = lazyRetry(() => import("../owner/activity/ActivityHome"));
const Howto = lazyRetry(() => import("../owner/howto/Howto"));

const Coach = lazyRetry(() => import("../coach/Coach"));

import FullPageSpinner from "./FullPageSpinner";

class AppRouter extends Component {
  getDashboardUrl = () => {
    const { user, isAuthenticated } = this.props;
    if (!isAuthenticated) {
      return "/";
    }

    switch (user.role) {
      case "admin":
        return "/admin";
      case "coach":
        return "/trainer/dashboard";
      default:
        return "/dashboard";
    }
  };

  componentDidMount() {
    const { history } = this.props;

    // We had an issue where the Helpwise bubble was
    // obscuring the "Send" button in the chat UI. As a solution,
    // we'll only show the Helpwise bubble on the dashboard home
    // page. This listens for URL routing changes and calls the
    // appropriate Helpwise functions for showing and hiding the bubble.

    // Note that Helpwise only applies for trainers. For Heroes accounts,
    // they get a Botsify chat bubble, which doesn't seem to have the same
    // programmatic control. But Heroes don't have the chat functionality, so
    // we can leave that as a TODO.

    this.unsubscribe = history.listen(({ pathname }) => {
      if (window?.Helpwise) {
        if (pathname === '/trainer/dashboard') {
          // 'show' shows the whole expanded chat widget, and
          // 'hide' contracts it so that only the bubble is there
          window.Helpwise('show');
          window.Helpwise('hide');
        } else {
          // this hides it along with the bubble
          window.Helpwise('hideAlongWithIcon');
        }
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe?.();
  }

  render() {
    const {
      isAuthenticated,
      user,
      logOut,
      loadUser,
      loginUser,
      isPro,
    } = this.props;

    const isUser = user && user.role === "owner";
    const isCoach = user && user.role === "coach";
    const showNav = isAuthenticated && (isUser || isCoach);

    const dashboardUrl = this.getDashboardUrl();
    return (
      <ProContext.Provider value={isPro}>
        <Suspense fallback={<FullPageSpinner />}>
        <div className="app__inner">
          <Switch>

            <AdminRoute
              path={ROUTES.ADMIN.DASHBOARD}
              component={Admin}
              isAuthenticated={isAuthenticated}
              user={user}
              onLogout={logOut}
            />

            <CoachRoute
              path={ROUTES.COACH.DASHBOARD}
              component={Coach}
              isAuthenticated={isAuthenticated}
              user={user}
              onLogout={logOut}
              loadUser={loadUser}
            />

            <SetupRoute
              component={LoginPage}
              path={ROUTES.LOGIN}
              isAuthenticated={isAuthenticated}
              onLogin={loginUser}
            />

            <SetupRoute
              component={ForgotPassword}
              path={ROUTES.FORGOT_PASSWORD}
              isAuthenticated={isAuthenticated}
            />

            <SetupRoute
              component={NewPassword}
              path={ROUTES.NEW_PASSWORD}
              isAuthenticated={isAuthenticated}
            />

            <SetupRoute
              component={Register}
              path={ROUTES.REGISTER.DETAILS}
              isAuthenticated={isAuthenticated}
            />

            <SetupRoute
              component={Complete}
              path={ROUTES.REGISTER.COMPLETE}
              isAuthenticated={isAuthenticated}
              loginUser={loginUser}
            />

            <SetupRoute
              component={Onboarding}
              path={ROUTES.REGISTER.START}
              isAuthenticated={isAuthenticated}
            />

            <SetupRoute
              component={Purchase}
              path={ROUTES.PURCHASE}
              isAuthenticated={isAuthenticated}
            />

            <Route exact path={ROUTES.HOME}>
              {isAuthenticated ? (
                <Redirect to={dashboardUrl} />
              ) : (
                <Frontpage isPro={isPro} />
              )}
            </Route>

            <OwnerRoute
              path={ROUTES.OWNER.CHAT}
              component={Chat}
              isAuthenticated={isAuthenticated}
              user={user}
              condition={{ rule: user?.family?.coach }}
            />

            <OwnerRoute
              path={ROUTES.OWNER.DASHBOARD}
              component={Dashboard}
              isAuthenticated={isAuthenticated}
              user={user}
            />


            <OwnerRoute
              path={ROUTES.OWNER.TRAINING}
              component={Training}
              isAuthenticated={isAuthenticated}
              user={user}
            />
            <OwnerRoute
              path={ROUTES.OWNER.HISTORY}
              component={History}
              isAuthenticated={isAuthenticated}
              user={user}
            />

            <OwnerRoute
              path={ROUTES.OWNER.SETTINGS}
              component={Settings}
              isAuthenticated={isAuthenticated}
              user={user}
              onChange={loadUser}
              onLogout={logOut}
            />

            <OwnerRoute
              path={ROUTES.OWNER.ACHIEVEMENTS}
              component={Achievements}
              isAuthenticated={isAuthenticated}
              user={user}
            />

            <OwnerRoute
              path={ROUTES.OWNER.HEROBOARDS}
              component={Heroboards}
              isAuthenticated={isAuthenticated}
              user={user}
            />

            <OwnerRoute
              exact
              path={ROUTES.OWNER.GOALS}
              component={GoalDetails}
              isAuthenticated={isAuthenticated}
              user={user}
            />

            <OwnerRoute
              path={ROUTES.OWNER.FRIENDS}
              component={Friends}
              isAuthenticated={isAuthenticated}
              user={user}
            />

            <OwnerRoute
              path={ROUTES.OWNER.FEED}
              component={ActivityFeed}
              isAuthenticated={isAuthenticated}
              user={user}
            />

            <OwnerRoute
              path={ROUTES.OWNER.SOCIAL_OPT_IN}
              component={SocialOptIn}
              isAuthenticated={isAuthenticated}
              user={user}
              onChange={loadUser}
            />

            <OwnerRoute
              path={ROUTES.OWNER.ACTIVITY}
              component={ActivityHome}
              isAuthenticated={isAuthenticated}
              user={user}
            />

            <OwnerRoute
              path={ROUTES.OWNER.HOWTO}
              component={Howto}
              isAuthenticated={isAuthenticated}
              user={user}
            />

            <Route>
              <ErrorPage message="Page not found." />
            </Route>

          </Switch>
        </div>
        </Suspense>

        {showNav && (
          <>
            <Navigation user={user} />
          </>
        )}
      </ProContext.Provider>
    );
  }
}

AppRouter.propTypes = {
  isAuthenticated: PropTypes.bool,
  isPro: PropTypes.bool,
  user: PropTypes.shape({ role: PropTypes.string.isRequired }),
  logOut: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
};

AppRouter.defaultProps = {
  isAuthenticated: false,
  isPro: false,
};

export default withRouter(AppRouter);
