import React from "react";
import PropTypes from "prop-types";

import BannerJulie from './icons/BannerJulie';
import BannerBRB from './icons/BannerBRB';
import BannerPro from './icons/BannerPro';

const Logo = ({ isPro }) => {
  return (
    <div className={`d-flex justify-content-between banner-logo ${ isPro ? 'pro' : 'brb'}`}>
      <BannerJulie />
      { isPro ? <BannerPro /> : <BannerBRB /> }
    </div>
  );
};

Logo.propTypes = {
  isPro: PropTypes.bool
};

Logo.defaultProps = {
  isPro: false
};

export default Logo;
