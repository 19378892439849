import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import BSToast from 'react-bootstrap/Toast';
import qs from 'query-string';
import _ from 'lodash';

import { hideToast, SHOW_ACHIEVEMENT_TOAST } from '../../redux/actions';
import NAV_ROUTES from '../../constants/routes';

const Toast = () => {

  const toast = useSelector((state) => state.toast);
  const dispatch = useDispatch();

  const close = () => dispatch(hideToast());

  const getContents = (toast) => {
    if (!toast) return {};
    switch (toast.type) {
      case SHOW_ACHIEVEMENT_TOAST: {
        // array of achievement IDs that we've just unlocked
        const { achievements } = toast;

        // we'll pass the first one to the achievements 
        // screen so that it can be highlighted
        const to = {
          pathname: NAV_ROUTES.OWNER.ACHIEVEMENTS,
          search: qs.stringify({
            achievement: _.first(achievements)
          })
        };

        return {
          header: 'Yay!',
          body: (
            <span>
              You have unlocked a&nbsp; 
              <Link to={to} onClick={close}>
                new achievement!
              </Link>
            </span>
          )
        };
      }
      default:
        return {};
    }
  };

  const { header, body } = getContents(toast);
  const show = !!toast;

  return (
    <>
    { show &&
    <div className="toast-container">
      <BSToast show={show} 
        delay={12000} 
        autohide
        onClose={close}>
          <BSToast.Header>
            <strong className="mr-auto">
              {header}
            </strong>
          </BSToast.Header>
          <BSToast.Body>
            {body}
          </BSToast.Body>
      </BSToast>
    </div>
    }
    </>
  );
};

export default Toast;