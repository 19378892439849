import flow from 'lodash/fp/flow';
import find from 'lodash/fp/find';
import property from 'lodash/fp/property';

export function toBoundary(component, e) {
  // "convert" an exception thrown asynchronously to
  // an exception to be handled by React error boundary
  // by throwing the exception in setState
  
  component.setState(() => { throw e });
}

export function timeoutPromise(promise, timeoutMs) {
  const timeout = new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      reject("Promise timed out");
    }, timeoutMs); 
  });

  return Promise.race([ promise, timeout ]);
}

export function findFirstError(queryResults) {
  return flow(
    find('error'),
    property('error'),
  )(queryResults);
}