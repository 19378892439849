import React from "react";
import { Route, Redirect } from "react-router-dom";

export const CoachRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        rest.isAuthenticated && user.role === "coach" ? (
          <Component {...rest} {...props} user={user} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
    />
  );
};
