import React from "react";
import { Route, Redirect } from "react-router-dom";

export const SetupRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        rest.isAuthenticated ? (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        ) : (
          <Component user={user} {...rest} {...props} />
        )}
    />
  );
};
