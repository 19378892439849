import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import Div100vh from "react-div-100vh";

import { bcAuthenticate, setCredentials, setFriendData, setUserName } from "../../api/braincloud-api";

import AppRouter from "./AppRouter";
import ErrorBoundary from "../errorpages/ErrorBoundary";

import { 
  loadSelf, 
  loadDogs, 
  loadGameCredentials,
  loadChatToken
} from "../../api/me";
import { initChatClient } from "../../helpers/chat";

import FullPageSpinner from "./FullPageSpinner";
import Toast from "./Toast";

import { connect } from 'react-redux';
import _ from 'lodash';
import { useQueryClient } from "@tanstack/react-query";
import { ACTIVE_CAMPAIGN, HELP_CHAT } from "../../query/queryKeys";
import ThirdPartyScript from "./ThirdPartyScript";

const defaultState = { isAuthenticated: false, user: null };

// react-query is hooks only, so need to wrap the class component with a function component
const withQueryClient = (WrappedComponent) => {
  const C = (props) => {
    const queryClient = useQueryClient();
    return (
      <WrappedComponent queryClient={queryClient} {...props}>
      </WrappedComponent>
    );
  }

  return C;
};

const THIRD_PARTY_SCRIPTS = [ HELP_CHAT, ACTIVE_CAMPAIGN ];

class App extends Component {
  constructor(props) {
    super(props);
    const { isPro } = this.props;
    this.state = {
      ...defaultState,
      isLoading: true,
      isPro
    };

    _.bindAll(this, [
      'loginUser', 
      'loadUser', 
      'logOut', 
      'initPendo',
      'initUsetiful',
    ]);
  }

  async componentDidMount() {
    await this.loadUser();
  }

  async loginUser() {
    this.setState(() => ({ isLoading: true }), () => this.loadUser());
  }

  initPendo(user) {

    try {
      if (user.role === 'admin') return;

      if (window.pendo) {
        const data = {
          visitor: {
            id: user.id,
            name: user.first_name,
            email: user.email,
            role: user.analytics?.role,
          },
          account: {
            id: user.analytics?.account_id,
          }
        };

        console.log('Sending data to pendo', data);
        window.pendo.initialize(data);

      } else {
        console.log('Pendo has not been initialized');
      }

    } catch (e) {
      console.warn('Failed to initialize pendo', e);
    }
  }

  initUsetiful(user) {
    try {
      if (user.role === 'admin') return;

      if (window) {
        const key = document.querySelector('meta[name="usetiful-key"]')?.getAttribute('content');

        if (!key) {
          console.log('Usetiful has not been enabled');
          return;
        }

        window.usetifulTags = {
          firstName: user.first_name,
          role: user.analystics?.role,
          userId: user.id,
        };

        const r = document.createElement('script');
        r.async = 1;
        r.src = 'https://www.usetiful.com/dist/usetiful.js';
        r.setAttribute('id', 'usetifulScript');
        r.dataset.token = key;

        document.head.appendChild(r);

        console.log('Usetiful initialized');
      }

    } catch (e) {
      console.warn('Failed to initialize usetiful', e);
    }
  }

  // this gets called when the app first loads
  // initializes the braincloud session and does an initial 
  // sync with the user's current statistics
  async initGame(game_creds, me, dogs) {
    console.log('initGame called', game_creds, me);

    setCredentials(game_creds);
    try {
      const response = await bcAuthenticate();
      console.log('Successfully authenticated with braincloud', response);

      const { playerName, summaryFriendData } = response;

      // if the brainCloud user profile does not yet have 
      // the dog's name, update it
      const dogName = _.first(dogs).name;
      if (playerName !== dogName) {
        // update the dog name
        setUserName(dogName);
      }

      // use this field in the brainCloud profile to 
      // store the family's ID
      // For social features like leaderboards, each user
      // can see this field for other users. We'll use this 
      // to look up data in our own backend
      if (!summaryFriendData) {
        setFriendData({ 
          id: me.family.id,
        });
      }

    } catch (e) {
      console.log('Failed to authenticate with braincloud', e);
    }

  }

  async loadUser() {
    try {
      const [me, dogs] = await Promise.all([
        loadSelf(), 
        loadDogs(), 
      ]);

      // after logging in, we can initialize the third-party scripts
      // with the user's session
      THIRD_PARTY_SCRIPTS.forEach((queryKey) => {
        this.props.queryClient?.refetchQueries?.({ queryKey });
      });

      this.initPendo(me);
      this.initUsetiful(me);

      if (me.role === 'owner') {
        const game_creds = await loadGameCredentials();
        await this.initGame(game_creds, me, dogs);
      }

      if (me.role === 'coach' || (me.role === 'owner' && me?.family?.coach)) {
        try {
          const { token } = await loadChatToken();
          await initChatClient(token);
          console.log('Initialized chat client');
        } catch (e) {
          // If the initial connection times out, we can just swallow the exception 
          // here so that we don't block them from using the rest of the app. When they 
          // try to use chat functions, it will show an error message there, and they 
          // can reload.
        }
      }

      if (window.Median) {
        // this name will show on the Median list of visitors
        window.Median.identify(`${me.first_name} ${me.last_name} (${me.email})`);
      }

      this.setState({
        isLoading: false,
        isAuthenticated: true,
        user: { ...me, dogs }
      });
    } catch (e) {
      this.setState({
        ...defaultState,
        isLoading: false
      });
    }
  }

  async logOut() {
    // remove all cached items in react-query
    this.props.queryClient?.removeQueries?.();
    this.setState({ ...defaultState });
  }

  render() {
    const { isLoading, isAuthenticated, user, isPro } = this.state;
    if (isLoading) return <FullPageSpinner />;

    return (
      <ErrorBoundary>
        <BrowserRouter>
          <Div100vh>
            <div className="app">

              <Toast />

              <AppRouter
                isAuthenticated={isAuthenticated}
                user={user}
                logOut={this.logOut}
                loadUser={this.loadUser}
                loginUser={this.loginUser}
                isPro={isPro}
              />
            </div>
            { THIRD_PARTY_SCRIPTS.map((queryKey) => (
              <ThirdPartyScript key={queryKey[0]} queryKey={queryKey} />
            ))}
          </Div100vh>
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

App.defaultProps = {
  isPro: false
};

App.propTypes = {
  isPro: PropTypes.bool
};

export default withQueryClient(connect()(App));
