import { 
  CRATE_EXERCISE, 
  DEPARTURE_EXERCISE, 
  DIAB_EXERCISE, 
  MAT_EXERCISE 
} from "./exerciseTypes";

const NAV_ROUTES = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot_password",
  NEW_PASSWORD: "/new_password",
  REGISTER: {
    DETAILS: "/register/details",
    COMPLETE: "/register/complete",
    START: "/register",
  },
  PURCHASE: "/purchase",
  HOME: "/",
  OWNER: {
    DASHBOARD: "/dashboard",
    DASHBOARD_ASSISTANT: "/dashboard/assistant",
    CHAT: "/chat",
    TRAINING: "/training",
    TRAINING_END: "/training/end",
    TRAINING_EXERCISE: "/training/exercise",
    TRAINING_SETUP: "/training/setup",
    TRAINING_CATEGORY: "/training/category",
    TRAINING_CATEGORY_SPECIALIST: "/training/category-specialist",
    TRAINING_DIAB_SETUP: "/training/diab-setup",
    TRAINING_DIAB_EXERCISE: "/training/diab-exercise",
    TRAINING_DIAB_END: "/training/diab-end",
    TRAINING_CRATE_SETUP: "/training/crate-setup",
    TRAINING_CRATE_EXERCISE: "/training/crate-exercise",
    TRAINING_CRATE_END: "/training/crate-end",
    TRAINING_MAT_SETUP: "/training/mat-setup",
    TRAINING_MAT_EXERCISE: "/training/mat-exercise",
    TRAINING_MAT_END: "/training/mat-end",
    HISTORY: "/history",
    HISTORY_EDIT: "/history/edit",
    HISTORY_DIAB_EDIT: "/history/diab-edit",
    HISTORY_CRATE_EDIT: "/history/crate-edit",
    HISTORY_MAT_EDIT: "/history/mat-edit",
    SETTINGS: "/settings",
    DOGS: "/settings/dogs",
    DOGS_EDIT: "/settings/dogs/edit/",
    PROFILE: "/settings/profile",
    FAMILY: "/settings/family",
    SCENARIOS: "/settings/scenarios",
    ACHIEVEMENTS: "/activity/achievements",
    HEROBOARDS: "/activity/heroboards",
    GOALS: "/activity/goals",
    FRIENDS: "/activity/friends",
    FEED: "/activity/feed",
    SOCIAL_OPT_IN: "/activity/optin",
    ACTIVITY: "/activity",
    HOWTO: "/howto",
  },
  COACH: {
    DASHBOARD: "/trainer",
    MAX_CLIENT_LIMIT: "/trainer/max-client-limit",
    CHAT: "/trainer/chat",
    CHAT_VIEW: "/trainer/chat/view/",
    CLIENT: "/trainer/client/:family_id",
    HISTORY: "/trainer/client/:family_id/history",
    HISTORY_VIEW: "/trainer/client/:family_id/history/view/:exercise_id",
    HISTORY_DIAB: "/trainer/client/:family_id/history/diab/:exercise_id",
    HISTORY_CRATE: "/trainer/client/:family_id/history/crate/:exercise_id",
    HISTORY_MAT: "/trainer/client/:family_id/history/mat/:exercise_id",
    ADD_CLIENT: "/trainer/add-client",
    SETTINGS: "/trainer/settings",
    PROFILE: "/trainer/settings/profile",
    PLANS: "/trainer/plans",
    CREATE_PLAN: "/trainer/plans/:family_id/create",
    EDIT_PLAN: "/trainer/plans/:family_id/edit",
    VIEW_PLAN: "/trainer/plans/:family_id/view",
    CREATE_PLAN_DIAB: "/trainer/plans/:family_id/create-diab",
    VIEW_PLAN_DIAB: "/trainer/plans/:family_id/view-diab",
    SCENARIOS: "/trainer/client/:family_id/scenarios",
  },
  ADMIN: {
    DASHBOARD: "/admin",
  },
};

export const TRAINING_KEYS = {
  diab: "diab",
  desensitization: "desensitization",
  crate: "crate",
  mat: "mat",
};

export const getExerciseRouteForType = (type) => {
  switch (type) {
    case DEPARTURE_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_EXERCISE;
    case DIAB_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_DIAB_EXERCISE;
    case CRATE_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_CRATE_EXERCISE;
    case MAT_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_MAT_EXERCISE;
  }
  return null;
};

export const getSetupRouteForType = (type) => {
  switch (type) {
    case DEPARTURE_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_SETUP;
    case DIAB_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_DIAB_SETUP;
    case CRATE_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_CRATE_SETUP;
    case MAT_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_MAT_SETUP;
  }
  return null;
};

export const getEndRouteForType = (type) => {
  switch (type) {
    case DEPARTURE_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_END;
    case DIAB_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_DIAB_END;
    case CRATE_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_CRATE_END;
    case MAT_EXERCISE:
      return NAV_ROUTES.OWNER.TRAINING_MAT_END;
  }
  return null;
};

export const getHistoryRouteForType = (type) => {
  switch (type) {
    case DEPARTURE_EXERCISE:
      return NAV_ROUTES.OWNER.HISTORY_EDIT;
    case DIAB_EXERCISE:
      return NAV_ROUTES.OWNER.HISTORY_DIAB_EDIT;
    case CRATE_EXERCISE:
      return NAV_ROUTES.OWNER.HISTORY_CRATE_EDIT;
    case MAT_EXERCISE:
      return NAV_ROUTES.OWNER.HISTORY_MAT_EDIT;
  }
  return null;
};

const getClientHistoryRouteTemplate = (type) => {
  switch (type) {
    case DEPARTURE_EXERCISE:
      return NAV_ROUTES.COACH.HISTORY_VIEW;
    case DIAB_EXERCISE:
      return NAV_ROUTES.COACH.HISTORY_DIAB;
    case CRATE_EXERCISE:
      return NAV_ROUTES.COACH.HISTORY_CRATE;
    case MAT_EXERCISE:
      return NAV_ROUTES.COACH.HISTORY_MAT;
  }
  return null;
};

export const getClientHistoryRouteForType = (familyId, type) => {
  const routeTemplate = getClientHistoryRouteTemplate(type);
  return routeTemplate.replace(':family_id', familyId).replace('/:exercise_id', '');
};

export const getClientHistoryDetailRouteForType = (familyId, exerciseId, type) => {
  const routeTemplate = getClientHistoryRouteTemplate(type);
  return routeTemplate.replace(':family_id', familyId).replace(':exercise_id', exerciseId);
};

export const getRouteToClient = (key, familyId) =>
  NAV_ROUTES.COACH[key].replace(":family_id", familyId);

export default NAV_ROUTES;
