import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const AdminRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      rest.isAuthenticated && user.role == 'admin'
        ? <Component {...rest} {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
  );
}
