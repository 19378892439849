// coach/getClientsForDashboard
export const COACH_DASHBOARD = ['clients'];

// coach/getNotifications
export const COACH_NOTIFICATIONS = ['notifications'];

// coach/getLicense
export const COACH_LICENSE = ['licence'];

// coach/getClientsDetails
export const COACH_CLIENT = ['clientDetail'];
export const clientKey = 
  (id) => ([ ...COACH_CLIENT, id ]);

// braincloud-api/getFriends
export const FRIENDS = ['friends'];

// braincloud-api/findUser
const PLAYER = ['player'];
export const playerKey =
  (id) => ([ ...PLAYER, id ]);

// social/getActivityFeed
const ACTIVITY_FEED = ['feed'];
export const activityFeedKey = 
  (offset, friendIds) => ([ ...ACTIVITY_FEED, offset, { friendIds } ]);

// social/searchProfiles
export const SEARCH = ['search'];
export const searchKey = 
  (searchTerm) => ([ ...SEARCH, searchTerm ]);

// chat/initChatWithFamily or chat/initChatWithCoach
export const CHAT = ['chatSid'];
export const chatKey = 
  (id, isCoach) => ([ ...CHAT, id, { isCoach } ]);

export const CHAT_CHANNEL = ['chatChannel'];
export const chatChannelKey = 
  (channelSid) => ([ ...CHAT_CHANNEL, channelSid ]);

export const CHAT_MESSAGES = ['chatMessages'];
export const chatMessagesKey = 
  (channelSid, numPages) => ([ ...CHAT_MESSAGES, channelSid, { numPages } ]);

export const CHAT_UNREAD = ['chatUnread'];
export const chatUnreadKey = 
  (channelSid) => ([ ...CHAT_UNREAD, channelSid ]);

export const LEADERBOARD_TOP = ['leaderboardTop'];
export const leaderboardTopKey = 
  (leaderboardId, count) => ([ ...LEADERBOARD_TOP, leaderboardId, { count } ]);

export const LEADERBOARD_NEARBY = ['leaderboardNearby'];
export const leaderboardNearbyKey =
  (leaderboardId) => ([ ...LEADERBOARD_NEARBY, leaderboardId ]);

export const LEADERBOARD_FRIENDS = ['leaderboardFriends'];
export const leaderboardFriendsKey = 
  (leaderboardId) => ([ ...LEADERBOARD_FRIENDS, leaderboardId ]);

export const SOCIAL_PROFILES = ['socialProfiles'];
export const socialProfilesKey = 
  (familyIds) => ([ ...SOCIAL_PROFILES, { familyIds }]);

export const INVITES = ['invites'];

// braincloud profile summaries
export const SUMMARY_PROFILE = ['summaryProfile'];
export const summaryProfileKey =
  (id) => ([ ...SUMMARY_PROFILE, id ]);

export const ADMIN_AI_PREVIEW_LIST = ['AdminAIPreviewList'];
export const adminAIPreviewListKey =
  (random) => ([ ...ADMIN_AI_PREVIEW_LIST, random ]);

export const ADMIN_AI_PREVIEW_FAMILY = ['AdminAIPreviewFamily'];
export const adminAIPreviewFamily = 
  (familyId) => ([ ...ADMIN_AI_PREVIEW_FAMILY, familyId ]);

export const ADMIN_FAMILY_DETAIL = ['adminFamilyDetail'];
export const adminFamilyDetailKey = 
  (familyId) => ([ ...ADMIN_FAMILY_DETAIL, familyId ]);

export const HELP_CHAT = ['helpChat'];
export const ACTIVE_CAMPAIGN = ['activeCampaign'];

export const AI_INITIAL_SUMMARY = ['AIInitial'];
export const AI_LOGS = ['AILogs'];
export const AI_QUESTIONS = ['AIQuestions'];
export const AI_EXPANDED_SUMMARY = ['AIExpanded'];

export const ADMIN_AI_SETTINGS = ['AdminAISettings'];
export const ADMIN_AI_QUESTIONS = ['AdminAIQuestions'];
export const ADMIN_AI_ASK_QUESTION = ['AdminAIAskQuestion'];
export const adminAIAskQuestionKey = 
  (familyId, questionId) => ([ ...ADMIN_AI_ASK_QUESTION, familyId, questionId ]);

export const ADMIN_AI_RECENTS = ['AdminAIRecents'];
export const ADMIN_AI_HISTORY = ['AdminAIHistory'];
export const adminAIHistoryKey =
  (familyId) => ([ ...ADMIN_AI_HISTORY, familyId ]);

export const MAX_SCENARIOS = ['MaxScenarios'];

export const DASHBOARD_STATS = ['DashboardStats'];

export const TERMS_AND_CONDITIONS = ['TermsAndConditions'];

export const PRICES = ['Prices'];
export const pricesKey = (couponId) => ([ ...PRICES, { couponId }]);