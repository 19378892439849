import { get, post } from './requests';
import qs from 'query-string'

export function getPrices(couponId) {
  const url = qs.stringifyUrl({
    url: '/purchase/v1/prices',
    query: { coupon_id: couponId },
  }, { skipNull: true });
  return get(url).then(res => res.json());
}

export function makePurchase(values) {
  return post('/purchase/v1/buy', values).then(res => res.json());
}

export function getCoupon(code) {

  const url = qs.stringifyUrl({
    url: `/purchase/v1/coupon`,
    query: { code }
  }, { skipNull: true });

  return get(url).then(res => res.json());
}